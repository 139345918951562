/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { Card } from 'material-ui/Card';
import Scroll from 'react-scroll';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import Container from './Container';
import HomeSlide from './HomeSlide';
import HomeProduct from './HomeProduct'
import HomeCategory from './HomeCategory'
import HomeEvent from './HomeEvent'
/*--------------------------------------------------------------------------------------------------*/

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      products: []
    };
  }

  componentDidMount() {
    const { t, i18n } = this.props;
    Scroll.animateScroll.scrollToTop();
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Container>
        <Flex flex layout="column" >
          <Card zDepth={0}>
            <HomeSlide />
          </Card>          
          <Flex divider style={{ height: '10px' }} />
          <Card zDepth={0}>
            <HomeCategory />
          </Card>
          <Flex divider style={{ height: '10px' }} />
          <Flex layout="row" align="start start">
            <div className="ui-header-section-1">
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{t('hotproduct')}</div>
            </div>
            <div className="ui-tail-section" />
          </Flex>
          <Flex divider style={{ height: '10px' }} />
          <Card zDepth={0}>
            <HomeProduct />
          </Card>          
          <Flex divider style={{ height: '10px' }} />
          <Flex layout="row" align="start start">
            <div className="ui-header-section-1">
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{t('event')}</div>
            </div>
            <div className="ui-tail-section" />
          </Flex>
          <Flex divider style={{ height: '10px' }} />
          <Card zDepth={0}>
            <HomeEvent />
          </Card>
        </Flex>
      </Container>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(HomePage);
/*--------------------------------------------------------------------------------------------------*/
