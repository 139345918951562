/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import ImageGallery from 'react-image-gallery';
import ImageCustomer from './ImageCustomer'
import Slider from "react-slick";
import { Card } from 'material-ui/Card';
import ReactLoading from 'react-loading';
import Scroll from 'react-scroll';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import Container from './Container';
/*--------------------------------------------------------------------------------------------------*/
import ImageService01 from './company/company01.jpg';
import ImageService02 from './company/company02.jpg';
import ImageService03 from './company/company01.jpg';
/*--------------------------------------------------------------------------------------------------*/

function SliderNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />

  );
}

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = { infos: [], images: [], customers: [], loading: true };
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  componentDidMount() {
    const { t, i18n } = this.props;
    Scroll.animateScroll.scrollToTop();
    i18n.on('languageChanged', this.onLanguageChanged);
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/company/info')
      .then(response => response.json())
      .then(infos => this.setState({ infos: infos.company, images: infos.images, customers: infos.customers, loading: false }));
  }

  onLanguageChanged(lng) {
    this.setState({ state: this.state });
  }

  arrayImage(list) {
    let images = [];
    for (var x in list) {
      images.push({ "original": "https://www.sakayaautomate.com/upload/" + list[x].image });
    }
    return images;
  }

  render() {
    const { t, i18n } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      row: 2,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: true,
      slidesPerRow: 2,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            row: 2,
            slidesToShow: 8,
            slidesToScroll: 1,
            arrows: true,
            slidesPerRow: 2
          }
        },
        {
          breakpoint: 1200,
          settings: {
            row: 2,
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: true,
            slidesPerRow: 2
          }
        },
        {
          breakpoint: 800,
          settings: {
            row: 2,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            slidesPerRow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            row: 2,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            slidesPerRow: 2,
          }
        }
      ]
    };


    return (
      <Container>
        <Flex flex layout="column" >
          <Card style={{ textAlign: 'justify', fontSize: '20px', fontFamily: 'CSChatThai', marginTop: '0px' }} zDepth={0}>
            <div dangerouslySetInnerHTML={{ __html: (i18n.language === "th") ? this.state.infos.th_content : this.state.infos.en_content }}></div>
          </Card>
          <Card zDepth={0} style={{ minHeight: '28vw' }}>
            {this.state.loading ? (<Flex flex layout="column" align="center center" style={{ minHeight: '28vw' }}><ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={100} /></Flex>) : <ImageGallery items={this.arrayImage(this.state.images)} showThumbnails={false} lazyLoad={true} infinite={true} showIndex={false} slideDuration={3000} slideInterval={5000} showFullscreenButton={false} showPlayButton={false} showBullets={false} autoPlay={true} />}
          </Card>
          <Flex divider style={{ height: '20px' }} />
          <Card zDepth={0} style={{ marginRight: '0px' }}>
            <Slider {...settings} style={{ width: '100%' }}>
              {this.state.customers.map((image, index) => { return <ImageCustomer image={image.image} /> })}
            </Slider>
          </Card>
          <Flex divider style={{ height: '20px' }} />
          <Card style={{ textAlign: 'justify', fontSize: '20px', fontFamily: 'CSChatThai', marginTop: '15px' }} zDepth={0}>
            <Flex layout="column" >
              <Flex layout="row" align="start start">
                <Flex flex layout="row" align="start start">
                  <div className="ui-header-section-1">
                    <div style={{ textAlign: 'justify', verticalAlign: 'center', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '5px' }}>{t('ourservice.hr')}</div>
                  </div>
                  <div className="ui-tail-section" />
                </Flex>
              </Flex>
              <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="start start">
                <img style={{ maxWidth: '300px', padding: '10px' }} src={ImageService01} alt="" />
                <div style={{ padding: '10px' }}>
                  <span style={{ textAlign: 'justify', fontSize: '20px', fontFamily: 'CSChatThai', whiteSpace: 'pre-line' }}>{t('ourservice.p1')}<br /></span>
                </div>
              </Flex>
              <Flex layout="row" align="start start">
                <div className="ui-tail-section-divider" />
              </Flex>
              <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="start start">
                <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="start start">
                  <img style={{ maxWidth: '300px', padding: '10px' }} src={ImageService02} alt="" />
                  <div style={{ padding: '10px' }}>
                    <span style={{ textAlign: 'justify', fontSize: '20px', fontFamily: 'CSChatThai', whiteSpace: 'pre-line' }}>{t('ourservice.p2')}<br /></span>
                  </div>
                </Flex>
              </Flex>
              <Flex layout="row" align="start start">
                <div className="ui-tail-section-divider" />
              </Flex>
              <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="start start">
                <img style={{ maxWidth: '300px', padding: '10px' }} src={ImageService03} alt="" />
                <div style={{ padding: '10px' }}>
                  <span style={{ textAlign: 'right', fontSize: '20px', fontFamily: 'CSChatThai', whiteSpace: 'pre-line' }}>{t('ourservice.p3')}</span>
                </div>
              </Flex>
              <Flex divider style={{ height: '10px' }} />
            </Flex>
          </Card>
        </Flex>
      </Container>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(CompanyPage);
/*--------------------------------------------------------------------------------------------------*/