/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/

class CategoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = { image: props.image, th: props.th, en: props.en };
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="ui-card-category-style" src={this.state.image} alt="" />
            </div>
        );
    }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(CategoryItem);
/*--------------------------------------------------------------------------------------------------*/