/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import Paper from 'material-ui/Paper';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import ImageCatalog from './icon/catalog.jpg'
import ImageMap from './icon/map.jpg'
/*--------------------------------------------------------------------------------------------------*/

class FooterBar extends Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <div style={{ width: '100%'}}>
        <Flex align="start end" style={{ backgroundColor: '#FFFFFF', color: '#000000', height: '180px'}}>
          <Flex flex="100" layout style={{ padding: '10px', height: '30px', backgroundColor: '#E65100',width: '100%'}}>
            <div style={{ height: '10px', backgroundColor: '#E65100' }} />
          </Flex>
          <Flex layout style={{ padding: '10px', backgroundColor: '#000000' }}>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
            <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" flexXs="100" flexGtXs="100" flexSm="100" flexGtSm="100" flexMd="100" flexGtMd="100" flexLg="90" flexGtLg="60" align="space-between center" style={{ maxWidth: '1280px' }}>
              <Paper style={{ backgroundColor: '#000000', color: '#000000', minWidth: '250px' }} zDepth={0}>
                <Flex layout="column" align="space-between center" flex="grow" style={{ padding: '10px' }}>
                  <span style={{ width: '100%', color: '#E65100', textAlign: 'left', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('contact')}</span>
                  <span style={{ width: '100%', color: '#FFFFFF', textAlign: 'left', fontSize: '20px', fontFamily: 'CSChatThai', whiteSpace: 'pre-line'}}>{(t('address'))}</span>
                </Flex>
              </Paper>
              <Paper style={{ backgroundColor: '#000000', color: '#000000', minWidth: '250px' }} zDepth={0}>
                <Flex layout="column" align="space-between center" flex="grow" style={{ padding: '10px' }}>
                  <span style={{ width: '100%', color: '#E65100', textAlign: 'left', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('map')}</span>
                  <div style={{ height: '120px', backgroundColor: '#FFFFFF' }}>
                    <img style={{ height: '120px' }} src={ImageMap} alt=""/>
                  </div>
                </Flex>
              </Paper>
              <Paper style={{ backgroundColor: '#000000', color: '#000000', minWidth: '250px' }} zDepth={0}>
                <Flex layout="column" align="space-between center" flex="grow" style={{ padding: '10px' }}>
                  <span style={{ width: '100%', color: '#E65100', textAlign: 'left', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('brochures')}</span>
                  <div style={{ height: '120px', backgroundColor: '#FFFFFF' }}>
                    <img style={{ height: '120px' }} src={ImageCatalog} alt=""/>
                  </div>
                </Flex>
              </Paper>
            </Flex>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
          </Flex>
        </Flex>
      </div>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(FooterBar);
/*--------------------------------------------------------------------------------------------------*/