/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import Slider from "react-slick";
import ReactLoading from 'react-loading';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import ProductItem from './ProductItem'
/*--------------------------------------------------------------------------------------------------*/

function SliderNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', zIndex: '15' }}
            onClick={onClick}
        />
    );
}

function SliderPrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', zIndex: '15' }}
            onClick={onClick}
        />
    );
}

class CategoryListItem extends Component {
    constructor(props) {
        super(props);
        this.state = { image: props.image, th: props.th, en: props.en, order: props.order, id: props.id, search: props.search, textSearch: props.textSearch, items: [], loading: true };
        this.onLanguageChanged = this.onLanguageChanged.bind(this);

    }
    componentDidMount() {
        const { t, i18n } = this.props;

        if (this.state.search) {
            fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/product/cetegory/search/' + this.state.id + '/' + this.state.textSearch).then(response => response.json()).then(infos => this.setState({ items: infos.products, loading: false }));
        } else {
            fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/product/cetegory/' + this.state.id).then(response => response.json()).then(infos => this.setState({ items: infos.products, loading: false }));
        }
        i18n.on('languageChanged', this.onLanguageChanged)
    }

    onLanguageChanged(lng) {
        this.setState({ state: this.state });
    }

    render() {
        const { t, i18n } = this.props;
        const setting = {
            speed: 500,
            initialSlide: 0,
            infinite: false,
            variableWidth: true,
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            className: "slider variable-width",
            nextArrow: <SliderNextArrow />,
            prevArrow: <SliderPrevArrow />
        };
        return (
            <Flex flex style={{ width: '100%' }}>
                <Flex flex layout="row" align="start start">
                    <div className="ui-header-section-1">
                        <div style={{ textAlign: 'justify', verticalAlign: 'center', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '5px' }}>{(i18n.language === "th") ? this.state.th : this.state.en}</div>
                    </div>
                    <div className="ui-tail-section" />
                </Flex>
                <Flex divider style={{ height: '10px' }} />
                <Flex flex style={{ minHeight: '200px' }}>
                    {this.state.loading ? (<Flex flex layout="column" align="center center" style={{ minHeight: '200px' }}><ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={50} /></Flex>) : (<Slider {...setting}>{this.state.items.map((items, index) => { return <ProductItem image={'https://www.sakayaautomate.com/upload/' + items.image} th={items.th_name} en={items.en_name} id={items.id} key={items.id} /> })}</Slider>)}
                </Flex>
                <Flex flex divider style={{ height: '10px' }} />
            </Flex>
        );
    }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(CategoryListItem);
/*--------------------------------------------------------------------------------------------------*/