/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Card, CardMedia} from 'material-ui/Card';
import { Flex } from 'react-flex-material';
import Scroll from 'react-scroll';
import { translate } from 'react-i18next';
import Lightbox from 'react-images-and-youtube';
/*--------------------------------------------------------------------------------------------------*/
import ImageLogo from './icon/logo.png'
import ImageMap from './icon/map.jpg'
import ImageMarker from './icon/marker.svg'
/*--------------------------------------------------------------------------------------------------*/
import 'react-image-lightbox/style.css';
/*--------------------------------------------------------------------------------------------------*/
import Container from './Container';
/*--------------------------------------------------------------------------------------------------*/
const AnyReactComponent = ({ text }) => <div><img src={ImageMarker} style={{width: '40px', height: '40px'}}/></div>;
const mapImages = [ { src: ImageMap, index: 0 }];
/*--------------------------------------------------------------------------------------------------*/

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = { urrentImage: 0, lightboxIsOpen: false};
    this.openLightbox = this.openLightbox.bind(this);    
    this.closeLightbox = this.closeLightbox.bind(this);
  }

  componentDidMount() {
    Scroll.animateScroll.scrollToTop();
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <Container>       
        <Flex flex layout="column" style={{width: '100%' }}>
          <Flex layout="row" align="center center" >
          <div style={{ height: '80vw', width: '100%', maxHeight:'600px'}}>
            <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyC2oVrncpZ51aDi4cLsTLxSdclqL_NtOVg' }} defaultCenter={{ lat: 13.710278, lng: 100.644767 }} defaultZoom={17} >
              <AnyReactComponent lat={13.710278} lng={100.644767}/>
            </GoogleMapReact>
            </div>           
          </Flex>
          <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="space-between center" style={{marginTop:"20px"}}>
            <Flex flex="50" layout="row" align="center center">
              <Card zDepth={0}>                
                <Flex flex layout="row">
                <CardMedia>
                  <img style={{ maxWidth: '200px' }} src={ImageLogo} alt=""/>
                  </CardMedia>
                  <Flex divider style={{maxWidth: '10px', minWidth:'10px'}} />
                  <Flex flex layout="row" align="center center" hideXs hideSm>
                    <span style={{color: '#000000', textAlign: 'left', fontSize: '20px', fontFamily: 'CSChatThai', whiteSpace: 'pre-line'}}>{(t('address'))}</span>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
            <Flex flex="50" layout="row" align="center center">
              <Card zDepth={0}>
                <CardMedia>     
                  <a onClick={(e) => ((this.openLightbox(0, e)))}><img style={{ maxWidth: '200px' }} src={ImageMap} alt=""/></a>
                </CardMedia>
              </Card>
            </Flex>
          </Flex>
        </Flex>
        <Lightbox currentImage={0} isOpen={this.state.lightboxIsOpen} backdropClosesModal={true}  showImageCount={false} showCloseButton={false} onClose={() => this.setState({lightboxIsOpen: false })} images={mapImages} />
      </Container>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(ContactPage);
/*--------------------------------------------------------------------------------------------------*/