/*--------------------------------------------------------------------------------------------------*/
import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import registerServiceWorker from './registerServiceWorker';
import { I18nextProvider } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import './index.css';
/*--------------------------------------------------------------------------------------------------*/
import i18n from './i18n'
import App  from './App';
/*--------------------------------------------------------------------------------------------------*/
const muiTheme = getMuiTheme({
    palette: {
        textColor: '#000000'
    }
});
/*--------------------------------------------------------------------------------------------------*/

ReactDOM.render(<I18nextProvider i18n={i18n}><MuiThemeProvider muiTheme={muiTheme}><App/></MuiThemeProvider></I18nextProvider>, document.getElementById('root'));
registerServiceWorker();
/*--------------------------------------------------------------------------------------------------*/