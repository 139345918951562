/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { BrowserRouter, Router, Route, browserHistory} from 'react-router-dom';
import { translate} from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import HeaderBar from './components/HeaderBar';
import FooterBar from './components/FooterBar';
/*--------------------------------------------------------------------------------------------------*/
import HomePage    from './components/HomePage';
import EventById   from './components/EventById';
import ProductPage from './components/ProductPage';
import ProductById from './components/ProductById';
import CompanyPage from './components/CompanyPage';
import ContactPage from './components/ContactPage';
/*--------------------------------------------------------------------------------------------------*/
import './App.css';
/*--------------------------------------------------------------------------------------------------*/

class App extends Component {
  render() {
    return (
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          <Flex layout="column" align="center center">
            <HeaderBar />
            <Route exact={true} path="/" component={HomePage} />
            <Route path="/home" component={HomePage} />
            <Route path="/company" component={CompanyPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/products" component={ProductPage} />
            <Route path="/event/:id?" component={EventById} />
            <Route path="/product/:id?" component={ProductById} />
            <FooterBar />
          </Flex>
        </div>
      </BrowserRouter>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(App);
/*--------------------------------------------------------------------------------------------------*/
