/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
/*--------------------------------------------------------------------------------------------------*/

class Container extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100%', marginTop: '110px'}}> 
        <Flex align="center center" style={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
          <Flex layout style={{ padding: '10px'}}>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
            <Flex layout flexXs="100" flexGtXs="100" flexSm="100" flexGtSm="100" flexMd="100" flexGtMd="100" flexLg="90" flexGtLg="60" align="space-between center" style={{ maxWidth: '1280px' }}>
              <Flex layout="row" align="start center" flex="100">
                  {this.props.children}             
              </Flex>              
            </Flex>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
          </Flex>          
        </Flex>
    </div>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default Container;
/*--------------------------------------------------------------------------------------------------*/