/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/

class ImageCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = { image: props.image };
  }
  render() {
    return (
      <div>
        <img className="ui-image-customer-style" src={'https://www.sakayaautomate.com/upload/' + this.state.image} alt=""/>
      </div>      
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default ImageCustomer;
/*--------------------------------------------------------------------------------------------------*/