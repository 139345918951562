/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { Card } from 'material-ui/Card';
import Scroll from 'react-scroll';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import Container       from './Container';
import ProductSlide    from './ProductSlide';
import ProductCategory from './ProductCategory';
/*--------------------------------------------------------------------------------------------------*/

class ProductPage extends Component {
  
  componentDidMount() {
    Scroll.animateScroll.scrollToTop();   
  } 

  render() {
    return (
      <Container>
        <Flex flex layout="column" >
          <Card zDepth={0}>
            <ProductSlide />
          </Card>
          <Flex divider style={{ height: '20px' }} />
          <Card zDepth={0}>
            <ProductCategory />
          </Card>
        </Flex>
      </Container>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default ProductPage;
/*--------------------------------------------------------------------------------------------------*/

