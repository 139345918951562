/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import Slider from "react-slick";
import { Card } from 'material-ui/Card';
import { Flex } from 'react-flex-material';
import ReactLoading from 'react-loading';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import EventItem from './EventItem'
/*--------------------------------------------------------------------------------------------------*/

function SliderNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}

class HomeEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { events: [], loading : true};
  }

  componentDidMount() {  
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/home/event').then(response => response.json()).then(infos => this.setState({ events: infos.events, loading: false }));     
  }
  render() {
    const setting = {
      speed         : 500,
      initialSlide  : 0,
      infinite      : false,      
      variableWidth : true,
      centerMode    : false,
      slidesToShow  : 1,
      slidesToScroll: 1,
      className: "slider variable-width",
      nextArrow: <SliderNextArrow />, 
      prevArrow: <SliderPrevArrow />      
    };
    return (
      <Card zDepth={0} style={{minHeight : '200px'}}>
       <Flex flex layout="column" align="center center" style={{minHeight:'200px'}}>
          {this.state.loading ? <ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={50}/> : <Slider {...setting}> {this.state.events.map((items, index) => { return <EventItem image={'https://www.sakayaautomate.com/upload/' +  items.image} th={items.th_name} en={items.en_name} id={items.id} key={items.id} /> })}</Slider>}
        </Flex>
      </Card>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default HomeEvent;
/*--------------------------------------------------------------------------------------------------*/