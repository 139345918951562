/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { Card } from 'material-ui/Card';
import { Tabs, Tab } from 'material-ui/Tabs';
import Divider from 'material-ui/Divider';
import ReactLoading from 'react-loading';
import Scroll from 'react-scroll';
import { translate } from 'react-i18next';
import Lightbox from 'react-images-and-youtube';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import Container from './Container';
import YoutubeLogo from './icon/logo-youtube.svg'
/*--------------------------------------------------------------------------------------------------*/

class ProductById extends Component {
  constructor(props) {
    super(props);
    this.state = { id: "", th1: "", th2: "", en1: "", en2: "", image: "", image1: "", image2: "", image3: "", image4: "", image5: "", youtube: "", th_voltage: "", th_motor: "",th_material: "",th_dimension: "",th_weight: "",en_voltage: "",en_motor: "",en_material: "",en_dimension: "",en_weight: "", displays: [], images: [], youtubeId : "", currentImage: 0, lightboxIsOpen: false, loading: true };
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  componentDidMount() {
    const { t, i18n } = this.props;
    Scroll.animateScroll.scrollToTop();
    i18n.on('languageChanged', this.onLanguageChanged)
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/product/' + this.props.match.params.id).then(response => response.json()).then(infos => this.setState((infos.product != null) ? { th1: infos.product.th_name, th2: infos.product.th_content, en1: infos.product.en_name, en2: infos.product.en_content, id: infos.product.id, image: this.emptyString(infos.product.image), image1: this.emptyString(infos.product.image1), image2: this.emptyString(infos.product.image2), image3: this.emptyString(infos.product.image3), image4: this.emptyString(infos.product.image4), image5: this.emptyString(infos.product.image5), youtube: this.emptyString(infos.product.youtube),th_voltage: this.emptyString(infos.product.th_voltage), th_motor: this.emptyString(infos.product.th_motor),th_material: this.emptyString(infos.product.th_material),th_dimension: this.emptyString(infos.product.th_dimension),th_weight: this.emptyString(infos.product.th_weight),en_voltage: this.emptyString(infos.product.en_voltage),en_motor: this.emptyString(infos.product.en_motor),en_material: this.emptyString(infos.product.en_material),en_dimension: this.emptyString(infos.product.en_dimension),en_weight: this.emptyString(infos.product.en_weight),loading: false } : {loading: false}));
  }

  onLanguageChanged(lng) {
    this.setState({ state: this.state });
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  emptyString(s) {
    if (s == null) {
        return "";
    }
    return s;
  }

  prepareImage() {
    const getYoutubeId = require('get-video-id');

    var index = 0;
    this.state.displays = [];
    this.state.youtubeId = "";
    this.state.images[0] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[1] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[2] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[3] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[4] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[5] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    this.state.images[6] = { src: "https://www.sakayaautomate.com/upload/image-400x400.png", index: 0 };
    if ((this.state.image) != "") {
      this.state.images[0] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image, index: index++ };
      this.state.displays.push(this.state.images[0]);
    }
    if (this.state.image1 != "") {
      this.state.images[1] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image1, index: index++ };
      this.state.displays.push(this.state.images[1]);
    }
    if (this.state.image2 != "") {
      this.state.images[2] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image2, index: index++ };
      this.state.displays.push(this.state.images[2]);
    }
    if (this.state.image3 != "") {
      this.state.images[3] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image3, index: index++ };
      this.state.displays.push(this.state.images[3]);
    }
    if (this.state.image4 != "") {
      this.state.images[4] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image4, index: index++ };
      this.state.displays.push(this.state.images[4]);
    }
    if (this.state.image5 != "") {
      this.state.images[5] = { src: "https://www.sakayaautomate.com/upload/" + this.state.image5, index: index++ };
      this.state.displays.push(this.state.images[5]);
    }
    if (this.state.youtube != "") {     
        this.state.youtubeId = getYoutubeId(this.state.youtube).id;
        this.state.images[6] = { src: "https://img.youtube.com/vi/" + this.state.youtubeId + "/0.jpg", index: index++ };
        this.state.displays.push({ youtubeId: this.state.youtubeId });
    }
    return this.state.displays;
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Container>
        <Flex flex layout="column" >
          <Flex layout="row" align="start start">
            <div className="ui-header-section-1" hidden={this.state.loading}>
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{(i18n.language === "th") ? this.state.th1 : this.state.en1}</div>
            </div>
            <div className="ui-tail-section" hidden={this.state.loading}></div>
          </Flex>
          <Card zDepth={0} style={{ minHeight: '200px', paddingTop: '10px', paddingBottom: '10px' }}>
            <Lightbox currentImage={this.state.currentImage} backdropClosesModal={true}  showCloseButton={false} isOpen={this.state.lightboxIsOpen} onClickNext={this.gotoNext} onClickPrev={this.gotoPrevious} onClose={this.closeLightbox} images={this.prepareImage()} />
            {this.state.loading ? (<Flex flex layout="column" align="center center" style={{ minHeight: '28vw' }}><ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={100} /></Flex>) :
              (
                <Flex flex>
                  <Flex flex layout="row" align="space-between center" hideXs style={{maxWidth: '1280px'}}>
                    <Flex flex layout="column" style={{ maxWidth: '240px' }} >
                      <a onClick={(e) => ((this.state.image1 != "" ? this.openLightbox(this.state.images[1].index, e) : ""))}><img className="ui-card-image-lightbox" src={this.state.images[1].src} /></a>
                      <a onClick={(e) => ((this.state.image2 != "" ? this.openLightbox(this.state.images[2].index, e) : ""))}><img className="ui-card-image-lightbox" src={this.state.images[2].src} /></a>
                      <a onClick={(e) => ((this.state.image3 != "" ? this.openLightbox(this.state.images[3].index, e) : ""))}><img className="ui-card-image-lightbox" src={this.state.images[3].src} /></a>
                    </Flex>
                    <Flex flex layout="column" align="center center">
                      <div style={{ maxHeight: '400px', maxWidth: '400px'}}>
                        <a onClick={(e) => this.openLightbox(0, e)}><img className="ui-card-image-center-lightbox" src={this.state.images[0].src} alt="" /></a>
                      </div>
                    </Flex>
                    <Flex flex layout="column" style={{ maxWidth: '240px' }}>
                      <a onClick={(e) => ((this.state.image4 != "" ? this.openLightbox(this.state.images[4].index, e) : ""))}><img className="ui-card-image-lightbox" src={this.state.images[4].src} alt="" /></a>
                      <a onClick={(e) => ((this.state.image5 != "" ? this.openLightbox(this.state.images[5].index, e) : ""))}><img className="ui-card-image-lightbox" src={this.state.images[5].src} alt="" /></a>
                      <a onClick={(e) => ((this.state.image6 != "" ? this.openLightbox(this.state.images[6].index, e) : ""))}><div style={{ position: "relative" }}><img className="ui-card-image-lightbox" src={this.state.images[6].src}></img><img className="ui-card-image-youtube-centered" style={{ width: '88px', height: '30px' }} src={YoutubeLogo} alt="" /></div></a>
                    </Flex>
                  </Flex>

                  <Flex flex layout="column" showXs hideGtXs hideSm hideGtSm hideMd hideGtMd hideLg hideGtLg>
                    <Flex flex="95%" layout="column" align="center center">
                      <a onClick={(e) => this.openLightbox(0, e)}><img className="ui-card-image-center-lightbox-xs" src={this.state.images[0].src} alt="" /></a>
                    </Flex>
                    <Flex divider style={{ height: '10px' }} />
                    <Flex flex layout="row" align="space-between center" >
                      <Flex flex='50%' layout="column" style={{ maxWidth: '320px' }} >
                        <a onClick={(e) => ((this.state.image1 != "" ? this.openLightbox(this.state.images[1].index, e) : ""))}><img className="ui-card-image-lightbox-xs" src={this.state.images[1].src} /></a>
                        <a onClick={(e) => ((this.state.image2 != "" ? this.openLightbox(this.state.images[2].index, e) : ""))}><img className="ui-card-image-lightbox-xs" src={this.state.images[2].src} /></a>
                        <a onClick={(e) => ((this.state.image3 != "" ? this.openLightbox(this.state.images[3].index, e) : ""))}><img className="ui-card-image-lightbox-xs" src={this.state.images[3].src} /></a>
                      </Flex>
                      <Flex flex='50%' layout="column" style={{ maxWidth: '320px' }}>
                        <a onClick={(e) => ((this.state.image4 != "" ? this.openLightbox(this.state.images[4].index, e) : ""))}><img className="ui-card-image-lightbox-xs" src={this.state.images[4].src} alt="" /></a>
                        <a onClick={(e) => ((this.state.image5 != "" ? this.openLightbox(this.state.images[5].index, e) : ""))}><img className="ui-card-image-lightbox-xs" src={this.state.images[5].src} alt="" /></a>
                        <a onClick={(e) => ((this.state.image6 != "" ? this.openLightbox(this.state.images[6].index, e) : ""))}><div style={{ position: "relative" }}><img className="ui-card-image-lightbox-xs" src={this.state.images[6].src}></img><img className="ui-card-image-youtube-centered" style={{ width: '88px', height: '30px' }} src={YoutubeLogo} alt="" /></div></a>
                      </Flex>
                    </Flex>
                    <Flex divider style={{ height: '10px' }} />
                  </Flex>
                </Flex>
              )}
          </Card>
          <Flex divider style={{ height: '10px' }} />
          <Card zDepth={0} hidden={this.state.loading}>
             <Tabs className="ui-card-tab-style" initialSelectedIndex={1}> 
              <Tab style={{backgroundColor: 'white', fontSize: '22px', fontFamily: 'CSChatThai', color: 'black' }} label="" disabled></Tab>
              <Tab style={{minWidth:'140px', backgroundColor: 'white', fontSize: '22px', fontFamily: 'CSChatThai', color: 'black',flexGrow: '1'}} label={t('characteristic')}>
                <Card zDepth={0} style={{ minHeight: '200px', padding: '20px' }}>
                  <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }} dangerouslySetInnerHTML={{ __html: (i18n.language === "th") ? this.state.th2.toString() : this.state.en2.toString() }} ></div>
                </Card>
              </Tab>
              <Tab style={{minWidth:'140px',backgroundColor: 'white', fontSize: '22px', fontFamily: 'CSChatThai', color: 'black',flexGrow: '1'}} label={t('specification')}>
                <Flex flex layout="column" align="center center" style={{ width: '100%', height: '100%' }}>
                  <Card zDepth={0} style={{ minHeight: '200px', padding: '20px', width: '100%', maxWidth: '600px' }}>
                    <Flex flex layout="row" align="start start">
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{t('spec.p1')}</div>
                      </Flex>
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{(i18n.language === "th") ? this.state.th_voltage : this.state.en_voltage}</div>
                      </Flex>
                    </Flex>
                    <Divider />
                    <Flex flex layout="row" align="start start">
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{t('spec.p2')}</div>
                      </Flex>
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{(i18n.language === "th") ? this.state.th_motor: this.state.en_motor}</div>
                      </Flex>
                    </Flex>
                    <Divider />
                    <Flex flex layout="row" align="start start">
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{t('spec.p3')}</div>
                      </Flex>
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{(i18n.language === "th") ? this.state.th_material: this.state.en_material}</div>
                      </Flex>
                    </Flex>
                    <Divider />
                    <Flex flex layout="row" align="start start">
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{t('spec.p4')}</div>
                      </Flex>
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{(i18n.language === "th") ? this.state.th_dimension : this.state.en_dimension}</div>
                      </Flex>
                    </Flex>
                    <Divider />
                    <Flex flex layout="row" align="start start">
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{t('spec.p5')}</div>
                      </Flex>
                      <Flex flex="50">
                        <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }}>{(i18n.language === "th") ? this.state.th_weight : this.state.en_weight}</div>
                      </Flex>
                    </Flex>
                    <Divider />
                  </Card>
                </Flex>
              </Tab>
              <Tab style={{backgroundColor: 'white', fontSize: '22px', fontFamily: 'CSChatThai', color: 'black' }} label="" disabled />
            </Tabs>
          </Card>
        </Flex>
      </Container >
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(ProductById);
/*--------------------------------------------------------------------------------------------------*/

