/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { Card } from 'material-ui/Card';
import { Flex } from 'react-flex-material';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom'
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
/*--------------------------------------------------------------------------------------------------*/

class ProductSlide extends Component {
  constructor(props) {
    super(props);
    this.state = { slides: [], loading: true, currentIndex : 0 };
    this.imageClick = this.imageClick.bind(this);
    this.imageIndex = this.imageIndex.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    if (this.state.slides[this.state.currentIndex].product_id != "") {
        this.props.history.push("/product/" + this.state.slides[this.state.currentIndex].product_id); 
    }
  }

  imageIndex(index) {
    this.setState({currentIndex : index});
    console.log('index' + index);
  }

  componentDidMount() {
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/home/slide').then(response => response.json()).then(infos => this.setState({ slides: infos.products, loading: false }));
  }

  arrayImage(list) {
    let images = [];
    for (var x in list) {
      images.push({ "original": "https://www.sakayaautomate.com/upload/" + list[x].image});
    }
    return images;
  }
  render() {
    return (
      <Card zDepth={0} style={{ minHeight: '28vw' }}>
        {this.state.loading ? (<Flex flex layout="column" align="center center" style={{minHeight:'28vw'}}><ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={100} /></Flex>) : <ImageGallery items={this.arrayImage(this.state.slides)} showThumbnails={false} lazyLoad={false} infinite={true} showIndex={false} slideDuration={3000} slideInterval={5000} showFullscreenButton={false} showPlayButton={false} showBullets={false} autoPlay={true} onClick={this.imageClick} onSlide={this.imageIndex}/>}
       </Card>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default withRouter(ProductSlide);
/*--------------------------------------------------------------------------------------------------*/