/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { Card } from 'material-ui/Card';
import { Tabs, Tab } from 'material-ui/Tabs';
import Divider from 'material-ui/Divider';
import ReactLoading from 'react-loading';
import Scroll from 'react-scroll';
import { translate } from 'react-i18next';
import Lightbox from 'react-images-and-youtube';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import Container from './Container';
import YoutubeLogo  from './icon/logo-youtube.svg'
/*--------------------------------------------------------------------------------------------------*/

class EventById extends Component {
  constructor(props) {
    super(props);
    this.state = { id: "", th_name: "", th_html: "", en_name: "", en_html: "", image : "", currentImage: 0, lightboxIsOpen: false, loading: true };
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  componentDidMount() {
    const {i18n} = this.props;
    Scroll.animateScroll.scrollToTop();
    i18n.on('languageChanged', this.onLanguageChanged)
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/event/' + this.props.match.params.id).then(response => response.json()).then(infos => this.setState({ th_name : infos.event.th_name, th_html : infos.event.th_text, en_name :infos.event.en_name, en_html : infos.event.en_text, image : infos.event.image, id : infos.event.id, loading: false }));
  }

  onLanguageChanged(lng) {
    this.setState({ state: this.state });
  }  

  render() {
    const { t, i18n } = this.props;
    return (
      <Container>
        <Flex flex layout="column" >
          <Flex layout="row" align="start start">
            <div className="ui-header-section-1" hidden={this.state.loading}>
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{ (i18n.language === "th") ? this.state.th_name :  this.state.en_name}</div>
            </div>
            <div className="ui-tail-section" hidden={this.state.loading}></div>
          </Flex>  
          <Card zDepth={0} style={{ minHeight: '200px', paddingTop: '10px', paddingBottom: '10px' }}>
            <img className="ui-card-image-event-center" src={"https://www.sakayaautomate.com/upload/" + this.state.image} alt="" />
          </Card>        
          <Flex divider style={{ height: '10px' }} />
          <Card zDepth={0} hidden={this.state.loading}>
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai' }} dangerouslySetInnerHTML={{ __html: (i18n.language === "th") ? this.state.th_html.toString() : this.state.en_html.toString()}} ></div>
          </Card>            
        </Flex>
      </Container >
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(EventById);
/*--------------------------------------------------------------------------------------------------*/

