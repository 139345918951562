/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import ImageLogo from './icon/logo.png'
import ImageLangTH from './icon/th.png'
import ImageLangEN from './icon/en.png'
/*--------------------------------------------------------------------------------------------------*/

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "TH"
    };
  }

  changeLanguage(lang, event) {  
    const { t, i18n } = this.props;
    i18n.changeLanguage(lang);  
    this.setState({lang: lang.toUpperCase()});
  } 

  render() {
    const { t, i18n } = this.props;
    return (
      <AppBar position="fixed" style={{ width: '100%', backgroundColor: '#FAFAFA', color: '#000000', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px', height: '100px' }}>
        <Flex align="center center" style={{ backgroundColor: '#FAFAFA', color: '#000000' }}>
          <Flex layout style={{ padding: '10px', height: '60px' }}>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
            <Flex layout flexXs="100" flexGtXs="100" flexSm="100" flexGtSm="100" flexMd="100" flexGtMd="100" flexLg="90" flexGtLg="60" align="space-between center" style={{ maxWidth: '1280px' }}>
              <Flex layout="row" align="start center">
                <Flex>
                  <img style={{ height: '40px', width: '40px' }} src={ImageLogo} alt="" />
                </Flex>
                <Flex hideXs showGtXs showSm showGtSm showMd showGtMd showLg showGtLg>
                  <div style={{ marginLeft: '10px', fontSize: '20px', fontFamily: 'CSChatThai' }} > WELCOME TO SAKAYA AUTOMATE CO.,LTD </div>
                </Flex>
              </Flex>
              <Flex layout="row" align="end center">
                <Flex>
                  <span style={{ fontSize: '20px', fontFamily: 'CSChatThai', marginRight: '10px' }}>{this.state.lang}</span>
                </Flex>
                <Flex divider style={{ width: '4px' }} />
                <Flex>
                  <a onClick={(e) => this.changeLanguage("th", e)  }><img style={{ maxWidth: '32px' }} src={ImageLangTH} alt="" /></a>
                </Flex>
                <Flex divider style={{ width: '4px' }} />
                <Flex>
                  <a onClick={(e) => this.changeLanguage("en" ,e)}><img style={{ maxWidth: '32px' }} src={ImageLangEN} alt="" /></a>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
          </Flex>
          <Flex layout style={{ padding: '10px', height: '30px' }}>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
            <Flex layout flex flexXs="100" flexGtXs="100" flexSm="100" flexGtSm="100" flexMd="100" flexGtMd="100" flexLg="90" flexGtLg="60" align="end center" style={{ maxWidth: '1280px' }}>
              <Flex layout="row" align="center center" style={{ width: 'auto', paddingLeft: '10px', paddingRight:'10px'}}>
                <Link to="/home" style={{ textDecoration: 'none', decoration: 'none', outline: 'none', color: '#000000', fontSize: '20px', fontFamily: 'CSChatThai' }}> {t('home')}</Link>
              </Flex>
              <Flex divider style={{ width: '1px', border: '1px solid #000000' }} />
              <Flex layout="row" align="center center" style={{ width: 'auto', paddingLeft: '10px', paddingRight:'10px'}}>
                <Link to="/products" style={{ textDecoration: 'none', decoration: 'none', outline: 'none', color: '#000000', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('product')}</Link>
              </Flex>
              <Flex divider style={{ width: '1px', border: '1px solid #000000' }} />
              <Flex layout="row" align="center center" style={{ width: 'auto',paddingLeft: '10px', paddingRight:'10px' }}>
                <Link to="/company" style={{ textDecoration: 'none', decoration: 'none', outline: 'none', color: '#000000', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('company')}</Link>
              </Flex>
              <Flex divider style={{ width: '1px', border: '1px solid #000000' }} />
              <Flex layout="row" align="end center" style={{ width: 'auto',paddingLeft: '10px', paddingRight:'0px'}}>
                <Link to="/contact" style={{ textDecoration: 'none', decoration: 'none', outline: 'none', color: '#000000', fontSize: '20px', fontFamily: 'CSChatThai' }}>{t('contact')}</Link>
              </Flex>
            </Flex>
            <Flex flexXs="0" flexGtXs="0" flexSm="0" flexGtSm="0" flexMd="0" flexGtMd="0" flexLg="5" flexGtLg="20" />
          </Flex>
        </Flex>
      </AppBar>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(HeaderBar);
/*--------------------------------------------------------------------------------------------------*/
