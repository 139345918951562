/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import Slider from "react-slick";
import { Card } from 'material-ui/Card';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import ProductItem from './ProductItem'
/*--------------------------------------------------------------------------------------------------*/
import json from './homeContent.json'
/*--------------------------------------------------------------------------------------------------*/

function SliderNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />

  );
}

class ProductSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { products: props.products, textSearch: props.textSearch };
    console.log("item :" + JSON.stringify(this.state.products + " text :" + this.state.textSearch));
  }

  componentDidMount() {

  }

  render() {
    const { t, i18n } = this.props;
    const setting = {
      speed: 500,
      initialSlide: 0,
      infinite: false,
      variableWidth: true,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slider variable-width",
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };
    return (
      <Flex flex style={{ width: '100%' }}>
        <Card zDepth={0} style={{ marginRight: '0px', minHeight: '200px', minWidth: '100px' }}>
          <Flex layout="row" align="start start">
            <div className="ui-header-section-1">
              <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{t('search')} "{this.state.textSearch}"</div>
            </div>
            <div className="ui-tail-section" />
          </Flex>
          <Flex divider style={{ height: '10px' }} />
          <Flex flex style={{ minHeight: '200px' }}>
            {(this.state.products.length > 0) ?
              (<Slider {...setting} >
                {this.state.products.map((items, index) => { return <ProductItem image={'https://www.sakayaautomate.com/upload/' + items.image} th={items.th_name} en={items.en_name} id={items.id} /> })}
              </Slider>
              ) : (<Flex flex style={{ minHeight: '200px' }} layout="column" align="center center">
                <Flex flex layout="column" align="center center">
                  <div style={{ textAlign: 'justify', fontSize: '22px', fontFamily: 'CSChatThai', marginLeft: '10px' }}>{t('nodata')} "{this.state.textSearch}"</div>
                </Flex>
              </Flex>)}
          </Flex>
          <Flex divider style={{ height: '10px' }} />
        </Card>
      </Flex>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(ProductSearch);
/*--------------------------------------------------------------------------------------------------*/