/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import Slider from "react-slick";
import { Card, Paper } from 'material-ui';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/
import CategoryItem from './CategoryItem'
/*--------------------------------------------------------------------------------------------------*/
import ImageCategory1 from './category/category1.png'
import ImageCategory2 from './category/category2.png'
import ImageCategory3 from './category/category3.png'
import ImageCategory4 from './category/category4.png'
import ImageCategory5 from './category/category5.png'
/*--------------------------------------------------------------------------------------------------*/

function SliderNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: '15' }}
      onClick={onClick}
    />
  );
}


class HomeCategory extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
  }

  render() {
    const { t, i18n } = this.props;
    const setting = {
      speed: 500,
      initialSlide: 0,
      infinite: false,
      variableWidth: true,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slider variable-width",
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };
    return (
      <div style={{ backgroundColor: '#ececec' }}>
        <Flex flex layoutXs="column">
          <Card zDepth={0}>
            <Slider {...setting}>
              <CategoryItem image={ImageCategory1} th={"บด"} en={"MASHED"} />
              <CategoryItem image={ImageCategory2} th={"ฆ่าเชื้อ"} en={"STERILIZE"} />
              <CategoryItem image={ImageCategory3} th={"อบแห้ง"} en={"DRY"} />
              <CategoryItem image={ImageCategory4} th={"ต้มแรงดัน"} en={"BOILER"} />
              <CategoryItem image={ImageCategory5} th={"กวน-ผสม"} en={"STIR-MIX"} />
            </Slider>
          </Card>
          <div className="ui-divider-10px-style" />
          <Card zDepth={0} style={{ backgroundColor: 'transparent', padding: '0px', margin: '0px' }}>
            <Flex flex layoutXs="column" layoutGtXs="column" layoutSm="row" layoutGtSm="row" layoutMd="row" layoutGtMd="row" layoutLg="row" layoutGtLg="row" align="center center">
              <Flex flex="5">
                <Flex divider style={{ height: '10px' }} />
              </Flex>
              <Flex width="250px" layout="column" align="center center">
                <Paper style={{ backgroundColor: '#ffffff', width: '250px', height: '80px' }}>
                  <Link to="/contact" style={{ textDecoration: 'none', decoration: 'none', color: '#000000' }}>
                    <Flex>
                      <div style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'CSChatThai', marginTop: '10px' }}>{t('contacts')}</div>
                    </Flex>
                    <Flex>
                      <div style={{ textAlign: 'center', fontSize: '32px', fontFamily: 'CSChatThai', marginTop: '-10px' }}>02-704-6450</div>
                    </Flex>
                  </Link>
                </Paper>
              </Flex>
              <Flex flex="20">
                <Flex divider style={{ height: '10px' }} />
              </Flex>
              <Flex width="250px" layout="column" align="center center">
                <Paper style={{ backgroundColor: '#ffffff', width: '250px', height: '80px' }}>
                  <Flex layoutFill align="center center">
                    <Link to="/products" style={{ textDecoration: 'none', decoration: 'none', color: '#000000' }}><div style={{ textAlign: 'center', fontSize: '35px', fontFamily: 'CSChatThai', verticalAlign: 'center', lineHeight: '80px' }}>{t('allproducts')}</div></Link>
                  </Flex>
                </Paper>
              </Flex>
              <Flex flex="5">
                <Flex divider style={{ height: '10px' }} />
              </Flex>
            </Flex>
            <Flex divider style={{ height: '10px' }} />
          </Card>
        </Flex>
      </div>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(HomeCategory);
/*--------------------------------------------------------------------------------------------------*/