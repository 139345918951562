/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { Card } from 'material-ui/Card';
import ReactLoading from 'react-loading';
import { Flex } from 'react-flex-material';
import { withRouter } from 'react-router-dom'
/*--------------------------------------------------------------------------------------------------*/
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/

class HomeSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      slides: []
    };
    this.onImageClick = this.onImageClick.bind(this);
  }

  componentDidMount() {
    fetch('https://www.sakayaautomate.com/administrator/api/v1/service.php/home/slide').then((response) => response.json()).then(infos => this.setState({ slides: infos.homes, loading: false }));
  }

  onImageClick(event) {
    this.props.history.push("/products");
  }

  arrayImage(list) {
    let images = [];
    for (var x in list) {
      images.push({ "original": "https://www.sakayaautomate.com/upload/" + list[x].image});
    }
    return images;
  }
  render() {
    return (
      <Card zDepth={0} style={{ minHeight: '28vw' }}>
        {this.state.loading ? (<Flex flex layout="column" align="center center" style={{ minHeight: '28vw' }}><ReactLoading type={"bars"} color={'#e0e0e0'} height={30} width={100} /></Flex>) : <ImageGallery items={this.arrayImage(this.state.slides)} showThumbnails={false} lazyLoad={true} infinite={true} showIndex={false} slideDuration={3000} slideInterval={5000} showFullscreenButton={false} showPlayButton={false} showBullets={false} autoPlay={true} onClick={this.onImageClick} />}
      </Card>
    );
  }
}
/*--------------------------------------------------------------------------------------------------*/
export default withRouter(HomeSlide);
/*--------------------------------------------------------------------------------------------------*/