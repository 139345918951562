/*--------------------------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Flex } from 'react-flex-material';
import { Card } from 'material-ui/Card';
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next';
/*--------------------------------------------------------------------------------------------------*/
import "./style/styles.css"
/*--------------------------------------------------------------------------------------------------*/

class ProductItem extends Component {
    constructor(props) {
        super(props);
        this.state = { image: props.image, th: props.th, en: props.en, id: props.id };
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    clickItem = () => {
        this.props.history.push("/product/" + this.state.id);
    }

    componentDidMount() {
        const { t, i18n } = this.props;
        i18n.on('languageChanged', this.onLanguageChanged)
    }

    onLanguageChanged(lng) {
        this.setState({ state: this.state });
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div onClick={this.clickItem}>
                <Card className="ui-card-customer-style" zDepth={0} style={{ border: '1px solid #e0e0e0' }}>
                    <Flex flex layout="column" align="center center">
                        <Flex>
                            <img className="ui-card-image-style" src={this.state.image} alt="" />
                        </Flex>
                        <Flex divider style={{ height: '5px' }} />
                        <Flex layout="column" align="end center">
                            <div style={{ textAlign: 'center', fontSize: '22px', fontFamily: 'CSChatThai', overflow: 'hidden', minHeight: '30px', maxHeight: '30px'}}>{(i18n.language === "th") ? this.state.th : this.state.en}</div>
                        </Flex>
                    </Flex>
                </Card>
            </div>
        );
    }
} 
/*--------------------------------------------------------------------------------------------------*/
export default translate("translations")(withRouter(ProductItem));
/*--------------------------------------------------------------------------------------------------*/